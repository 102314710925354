<template>
    <v-navigation-drawer
        bottom
        color="transparent"
        fixed
        height="auto"
        overlay-color="secondary"
        overlay-opacity=".8"
        temporary
        v-bind="$attrs"
        v-on="$listeners"
    >
        <v-list color="white" shaped>
            <v-list-item
                v-for="navItem in items"
                :key="navItem.name"
                :exact="navItem.name === 'home'"
                color="primary"
                @click="goTo(navItem)"
            >
                <v-list-item-content>
                    <v-list-item-title v-text="navItem.title" />
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: `HomeDrawer`,

    props: {
        goTo: {
            type: Function,
            default: () => {}
        },
        items: {
            type: Array,
            default: () => []
        }
    }
}
</script>
